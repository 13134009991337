import { useQuery } from "@tanstack/react-query";
import { getLiveboards } from "../api";
import { useAccessToken } from "../Auth/useAccessToken";
import { useThoughtSpotToken } from "../thoughtspot";

export const LiveboardsQueryKey = "liveboards";

export function useLiveboards() {
  const { accessToken } = useAccessToken();
  const { token: thoughtspotToken } = useThoughtSpotToken();

  return useQuery({
    queryKey: [LiveboardsQueryKey, accessToken, thoughtspotToken],
    queryFn: () => {
      return getLiveboards(accessToken, thoughtspotToken!);
    },
    enabled: Boolean(accessToken) && Boolean(thoughtspotToken),
  });
}
