import React from "react";
import { SvgIcon } from "../../svg";
import { Link, useLocation } from "react-router-dom";
import { MENU_BUTTONS } from "./buttons";
import { useSelf } from "../../hooks/useSelf";

export function MenuButtons() {
  const self = useSelf();
  const location = useLocation();

  const menuButtons = React.useMemo(() => {
    return MENU_BUTTONS(self.data);
  }, [self.data]);

  return menuButtons.map((button) => (
    <Link
      key={button.name}
      to={button.path}
      replace={button.replace}
      className={button.className(button.isSelected(location.pathname))}
    >
      <SvgIcon icon={button.icon} />
      <div>{button.label}</div>
    </Link>
  ));
}
