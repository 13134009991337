import { BASE_URL } from "../constants";
import * as models from "./models";
import { authMiddleware, request } from "./request";

export async function getStatus(): Promise<models.StatusResponse> {
  try {
    const resp = await request(`${BASE_URL}/status`);
    return resp.json();
  } catch (error) {
    console.error("Error fetching status:", error);
    return { available: false };
  }
}

export const UNAUTHORIZED: string = "UNAUTHORIZED";
export async function login(credentials: models.LoginDetails): Promise<models.LoginResponse> {
  const resp = await request(
    `${BASE_URL}/login`,
    {
      method: "POST",
      body: JSON.stringify(credentials),
    },
    false,
  );
  if (resp.status === 404) {
    return Promise.reject(UNAUTHORIZED);
  }
  return resp.json();
}

export async function getThoughtspotToken(accessToken: string): Promise<string> {
  const response = await request(`${BASE_URL}/integrations/thoughtspot/token`, {
    pre: [authMiddleware(accessToken)],
  });
  const res: { token: string } = await response.json();
  return res.token;
}

export async function getSelf(accessToken: string): Promise<models.User> {
  const res = await request(`${BASE_URL}/self`, {
    pre: [authMiddleware(accessToken)],
  });
  const user: models.User = await res.json();
  user.meta.norma.liveboard_categories_ordered ??= [];
  return user;
}

export async function getInactiveFoodicsAccounts(
  accessToken: string,
  businessId: string,
): Promise<models.FoodicsAccount[]> {
  if (models.isAllBusiness(businessId)) {
    throw new Error(
      "cannot GET /integrations/foodics/{business_id}/accounts/inactive in ALL BUSINESSES context",
    );
  }
  const res = await request(`${BASE_URL}/integrations/foodics/${businessId}/accounts/inactive`, {
    pre: [authMiddleware(accessToken)],
  });
  const json = await res.json();
  return json.data;
}

export async function getUsers(accessToken: string, businessId: string): Promise<models.User[]> {
  let url = `${BASE_URL}/businesses/${businessId}/users`;
  if (models.isAllBusiness(businessId)) {
    url = `${BASE_URL}/organization/users`;
  }
  const res = await request(url, {
    pre: [authMiddleware(accessToken)],
  });
  const json = await res.json();
  return json.data;
}

export async function getLiveboards(
  accessToken: string,
  thoughtspotToken: string,
): Promise<models.Liveboard[]> {
  const res = await request(`${BASE_URL}/integrations/thoughtspot/liveboards`, {
    pre: [authMiddleware(accessToken, thoughtspotToken)],
  });
  const json = await res.json();
  return json.data;
}

export async function setLiveboardsOrder(
  accessToken: string,
  orderedLiveboardCategories: models.OrderedLiveboardsCategory[],
): Promise<void> {
  await request(`${BASE_URL}/self/enterprise-web-app/liveboards-order`, {
    method: "POST",
    body: JSON.stringify(orderedLiveboardCategories),
    pre: [authMiddleware(accessToken)],
  });
}

export async function addFavoriteLiveboard(
  accessToken: string,
  thoughtspotToken: string,
  liveboardId: string,
): Promise<void> {
  await request(
    `${BASE_URL}/integrations/thoughtspot/liveboards/favorite?liveboard_id=${liveboardId}`,
    {
      method: "POST",
      pre: [authMiddleware(accessToken, thoughtspotToken)],
    },
  );
}

export async function removeFavoriteLiveboard(
  accessToken: string,
  thoughtspotToken: string,
  liveboardId: string,
): Promise<void> {
  await request(
    `${BASE_URL}/integrations/thoughtspot/liveboards/favorite?liveboard_id=${liveboardId}`,
    {
      method: "DELETE",
      pre: [authMiddleware(accessToken, thoughtspotToken)],
    },
  );
}

export async function requestResetPassword(businessRef: number, email: string): Promise<void> {
  await request(`${BASE_URL}/request-reset-password`, {
    method: "POST",
    body: JSON.stringify({ business_ref: businessRef, email: email }),
  });
}

export async function resetPassword(args: {
  newPassword: string;
  resetPasswordToken: string;
}): Promise<models.LoginResponse> {
  const res = await request(`${BASE_URL}/reset-password`, {
    method: "POST",
    body: JSON.stringify({
      newPassword: args.newPassword,
      token: args.resetPasswordToken,
    }),
  });
  return res.json();
}

export async function resendInvitations(accessToken: string, ids: string[]): Promise<void> {
  await request(`${BASE_URL}/integrations/foodics/accounts/invite`, {
    method: "POST",
    body: JSON.stringify({ ids }),
    pre: [authMiddleware(accessToken)],
  });
}

export async function onboardFoodicsAccounts(
  accessToken: string,
  businessId: string,
  accountIds: string[],
): Promise<void> {
  if (models.isAllBusiness(businessId)) {
    throw new Error(
      "cannot POST /integrations/foodics/{business_id}/accounts/onboard in ALL BUSINESSES context",
    );
  }
  await request(`${BASE_URL}/integrations/foodics/${businessId}/accounts/onboard`, {
    method: "POST",
    body: JSON.stringify({ ext_ids: accountIds }),
    pre: [authMiddleware(accessToken)],
  });
}

export async function getInitialOnboardingFoodicsAccounts(
  initialOnboardingToken: string,
): Promise<models.FoodicsAccount[]> {
  const res = await request(`${BASE_URL}/integrations/foodics/accounts/initial-onboarding`, {
    pre: [authMiddleware(initialOnboardingToken)],
  });
  const json = await res.json();
  return json.data;
}

export async function initiallyOnboardFoodicsAccounts(
  initialOnboardingToken: string,
  accountIds: string[],
): Promise<void> {
  await request(`${BASE_URL}/integrations/foodics/accounts/initial-onboarding`, {
    method: "POST",
    body: JSON.stringify({ ext_ids: accountIds }),
    pre: [authMiddleware(initialOnboardingToken)],
  });
}

export async function promoteUser(accessToken: string, userId: string): Promise<void> {
  await request(`${BASE_URL}/users/${userId}/promote`, {
    method: "POST",
    body: JSON.stringify({ userId: userId }),
    pre: [authMiddleware(accessToken)],
  });
}

export async function deactivateUser(accessToken: string, userId: string): Promise<void> {
  await request(`${BASE_URL}/users/${userId}`, {
    method: "DELETE",
    pre: [authMiddleware(accessToken)],
  });
}

export async function changeAppMode(accessToken: string, mode: models.AppMode): Promise<void> {
  const modeString = mode === models.AppMode.PRO ? "pro" : "lite";

  await request(`${BASE_URL}/self/enterprise-web-app/mode`, {
    method: "POST",
    body: JSON.stringify({ mode: modeString }),
    pre: [authMiddleware(accessToken)],
  });
}

export async function getAppMode(accessToken: string): Promise<models.AppMode> {
  const res = await request(`${BASE_URL}/self`, {
    pre: [authMiddleware(accessToken)],
  });
  const u: models.User = await res.json();
  return u.meta.norma.enterprise_web_mode === "lite" ? models.AppMode.LITE : models.AppMode.PRO;
}

export async function getBusinesses(accessToken: string): Promise<models.Business[]> {
  const res = await request(`${BASE_URL}/businesses`, {
    pre: [authMiddleware(accessToken)],
  });
  const json = await res.json();
  return json.data;
}

export async function getEmailSchedules(
  accessToken: string,
  thoughtspotToken: string,
): Promise<models.EmailSchedule[]> {
  const res = await request(`${BASE_URL}/integrations/thoughtspot/schedules`, {
    pre: [authMiddleware(accessToken, thoughtspotToken)],
  });
  const json = await res.json();
  return json.data;
}

export async function saveBusinessContext(accessToken: string, businessId: string): Promise<void> {
  await request(`${BASE_URL}/self/business-context`, {
    method: "POST",
    body: JSON.stringify({ business_context: businessId }),
    pre: [authMiddleware(accessToken)],
  });
}

export async function onboardBusinessInOrganization(accessToken: string) {
  const resp = await request(`${BASE_URL}/integrations/foodics/onboard-business-request`, {
    pre: [authMiddleware(accessToken)],
  });
  const { token } = (await resp.json()) as { token: string };
  window.location.href = `${BASE_URL}/integrations/foodics/onboard-business?token=${token}`;
}

export function installNewBusiness() {
  const url = new URL("/foodics-auth", BASE_URL);
  const params = new URLSearchParams({
    "client-type": "foodics-ebi",
  });
  window.location.href = `${url}?${params.toString()}`;
}

export type BusinessUpdateChangeset = {
  display_name: string;
};

export async function updateBusinessName(
  accessToken: string,
  businessId: string,
  changeset: BusinessUpdateChangeset,
): Promise<models.Business> {
  const response = await request(`${BASE_URL}/businesses/${businessId}`, {
    method: "PATCH",
    body: JSON.stringify(changeset),
    pre: [authMiddleware(accessToken)],
  });

  return response.json();
}
