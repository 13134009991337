import { SettingsBusinessesTable } from "./SettingsBusinessesTable";
import { PulseLoader } from "react-spinners";
import { useGetBusinesses } from "../../../hooks/useGetBusinesses";
import { useSearchParams } from "react-router-dom";
import { Dialog } from "../../../components/Dialog";
import * as Sentry from "@sentry/react";

export function SettingsBusinesses() {
  const {
    alreadyOnboardedInAnotherOrgError,
    alreadyOnboardedInSameOrgError,
    onCloseOnboardingErrorDialog,
  } = useOnboardingError();
  const businesses = useGetBusinesses();

  if (businesses.isLoading) {
    return (
      <div className="flex justify-center items-center pt-20">
        <PulseLoader color="lightgray" loading={true} size={15} speedMultiplier={0.6} />
      </div>
    );
  }

  if (!businesses.isSuccess) {
    Sentry.captureException(businesses.error, {
      extra: {
        message: "businesses.isSuccess is false",
        extra: {
          error: businesses.error,
        },
      },
    });
    // TODO(yannis): show something
    return null;
  }

  if (businesses.data.length === 0) {
    return (
      <div className="flex justify-center items-center">
        <span>No results</span>
      </div>
    );
  }

  return (
    <>
      <SettingsBusinessesTable businesses={businesses.data} />

      <Dialog
        show={alreadyOnboardedInAnotherOrgError}
        title={"Multi Business Error"}
        titleClassName={"text-sematicRed"}
        message={
          <div>
            The business you tried to onboard seems to belong to a different organization, please
            contact your customer success to link your Businesses.
          </div>
        }
        messageClassName={"px-20"}
        onConfirm={onCloseOnboardingErrorDialog}
        confirmText={"OK"}
      />

      <Dialog
        show={alreadyOnboardedInSameOrgError}
        title={"Already Onboarded Business"}
        titleClassName={"text-sematicRed"}
        message={
          <div>
            You seem to have onboarded a business that already exists in this Organization. Make
            sure you <span className={"text-sematicRed"}>logout from the console</span> and try
            again.
          </div>
        }
        messageClassName={"px-20"}
        onConfirm={onCloseOnboardingErrorDialog}
        confirmText={"OK"}
      />
    </>
  );
}

function useOnboardingError() {
  const inAnotherOrgParamName = "in-another-org";

  const [searchParams, setSearchParams] = useSearchParams();

  const alreadyOnboardedInAnotherOrgError = searchParams.get(inAnotherOrgParamName) === "true";
  const alreadyOnboardedInSameOrgError = searchParams.get(inAnotherOrgParamName) === "false";
  function onCloseOnboardingErrorDialog() {
    setSearchParams(
      (prev) => {
        prev.delete(inAnotherOrgParamName);
        return prev;
      },
      { replace: true },
    );
  }

  return {
    alreadyOnboardedInAnotherOrgError,
    alreadyOnboardedInSameOrgError,
    onCloseOnboardingErrorDialog,
  };
}
