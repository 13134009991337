import { useResendInvitation } from "../../../hooks/useResendInvitation";
import { customToast } from "../../../utils/customToast";
import { UserRowModel } from "./SettingsUsersTable";
import { useOnboardFoodicsAccounts } from "../../../hooks/useOnboardFoodicsAccounts";
import { useDeactivateUser } from "../../../hooks/useDeactivateUser";
import { usePromoteUser } from "../../../hooks/usePromoteUser";
import { useBusinessContext } from "../../../hooks/useBusinessContext";
import { UsersSettingsTab, UsersSettingsTabs } from "./tabs";
import { useSelf } from "../../../hooks/useSelf";
import * as Sentry from "@sentry/react";

export type UserContextMenuOptionSet = UsersSettingsTab;

export type UserContextMenuOption = {
  id: string;
  label: string;
  handler: () => Promise<void>;
  demoHandler: () => void;
  isDisabled?: boolean;
  isPending?: boolean;
};

export function useUserContextMenuOptions(
  optionsSet: UserContextMenuOptionSet,
  account: UserRowModel,
): UserContextMenuOption[] {
  const self = useSelf();

  const { business } = useBusinessContext();

  const resendInvitationMutation = useResendInvitation();
  const onboardFoodicsAccounts = useOnboardFoodicsAccounts();
  const deactivateUser = useDeactivateUser();
  const promoteUser = usePromoteUser();

  function onSuccess(msg: string) {
    customToast(msg, "success", "bottom");
  }

  function onError(msg: string, error?: unknown) {
    console.error(`An error occurred: msg: ${msg}, error: `, error);
    customToast(msg, "error", "bottom");
  }

  const resendInviteSuccess = () =>
    onSuccess("Invite was sent again, please tell the user to check their email.");
  const resendInviteOption: UserContextMenuOption = {
    id: "resend-invite",
    label: "Resend Invite",
    demoHandler: resendInviteSuccess,
    handler: () => {
      return resendInvitationMutation.mutateAsync(account.id, {
        onSuccess: resendInviteSuccess,
        onError: (error) => onError("Failed to resend invitation", error),
      });
    },
    isPending: resendInvitationMutation.isPending,
  };

  const inviteSuccess = () =>
    onSuccess("Invitation was sent, please tell the user to check their email.");
  const inviteOption: UserContextMenuOption = {
    id: "invite",
    label: "Invite",
    demoHandler: inviteSuccess,
    handler: () => {
      if (!business) {
        Sentry.captureEvent({
          message: "business is not expected to be undefined in invite's handler",
          level: "error",
        });
        return Promise.resolve();
      }
      return onboardFoodicsAccounts.mutateAsync(
        { businessId: business.id, accountIds: [account.id] },
        {
          onSuccess: inviteSuccess,
          onError: (error) => onError("Failed to send invite", error),
        },
      );
    },
    isPending: onboardFoodicsAccounts.isPending,
  };

  const deactivateSuccess = () =>
    onSuccess(
      "User was deactivated, if you want to activate them again, please search for them in the Inactive users tab.",
    );
  const deactivateOption: UserContextMenuOption = {
    id: "deactivate",
    label: "Deactivate",
    demoHandler: deactivateSuccess,
    handler: () => {
      return deactivateUser.mutateAsync(
        { userId: account.id },
        {
          onSuccess: deactivateSuccess,
          onError: (error) => onError("Failed to deactivate user", error),
        },
      );
    },
    isDisabled: !self.isSuccess || account.id === self.data.id,
    isPending: deactivateUser.isPending,
  };

  const makeAdminSuccess = () => onSuccess("User was successfully made an “Organization Admin“.");
  const makeAdminOption: UserContextMenuOption = {
    id: "make-admin",
    label: "Make Admin",
    demoHandler: makeAdminSuccess,
    handler: () => {
      return promoteUser.mutateAsync(
        { userId: account.id },
        {
          onSuccess: makeAdminSuccess,
          onError: (error) => onError("An error occurred", error),
        },
      );
    },
    isDisabled: account.is_root,
    isPending: promoteUser.isPending,
  };

  if (optionsSet === UsersSettingsTabs.active) {
    return [makeAdminOption, deactivateOption];
  }

  if (optionsSet === UsersSettingsTabs.pending) {
    return [resendInviteOption];
  }

  return [inviteOption];
}
