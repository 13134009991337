import React from "react";
import { useSelf } from "../hooks/useSelf";
import { useInitializeThoughtspot } from "../thoughtspot/useInitializeThoughtspot";
import { AuthContext, authContext } from "./context";
import { useAccessToken } from "./useAccessToken";
import * as Sentry from "@sentry/react";
import { mergeUserEmails } from "../api";
import { usePostHog } from "posthog-js/react";

export function AuthProvider({ children }: React.PropsWithChildren) {
  const posthog = usePostHog();
  const { accessToken } = useAccessToken();
  const isAuthenticated = Boolean(accessToken);

  const self = useSelf();
  const user = self.data;

  const thoughtspotStatus = useInitializeThoughtspot();

  const value = {
    isAuthenticated,
    thoughtspotStatus,
  } satisfies AuthContext;

  React.useEffect(() => {
    if (!isAuthenticated || !user) {
      Sentry.setUser(null);
      return;
    }
    Sentry.setUser({
      id: user.id,
      email: mergeUserEmails(user),
    });
  }, [isAuthenticated, user]);

  React.useEffect(() => {
    if (!isAuthenticated || !user) {
      posthog?.reset();
      return;
    }

    posthog?.identify(
      user.id,
      {
        emails: user.identities.map((i) => i.email),
        is_root: user.is_root,
        name: user.name,
      },
      {
        organization: {
          id: user.organization_id,
          name: user.organization.name,
        },
      },
    );

    posthog?.group("organization", user.organization.id, { name: user.organization.name });
  }, [posthog, isAuthenticated, user]);

  return <authContext.Provider value={value}>{children}</authContext.Provider>;
}
