import React from "react";
import { useLocation } from "react-router-dom";
import { LiveboardEmbed, Action } from "@thoughtspot/visual-embed-sdk/react";
import { ExpandVisualization } from "../../components/ExpandVisualization";
import { useExpandVisualizationStore } from "../../stores/useExpandVisualizationStore";
import { EXPAND_ACTION, LOCALE_OVERRIDE } from "../../thoughtspot/constants";
import { useGetRuntimeFilters } from "../../thoughtspot/useGetRuntimeFilters";
import { useCaptureLiveboardView } from "../../hooks/useCaptureLiveboardView";

interface OnCustomActionArgs {
  data: {
    id: string;
    embedAnswerData: {
      vizId: string;
    };
  };
}

const hiddenActions: Action[] = [
  Action.Edit,
  Action.Save,
  Action.CopyAndEdit,
  Action.Schedule,
  Action.SchedulesList,
  Action.RequestVerification,
  Action.ShowUnderlyingData,
  Action.EditTML,
  Action.ExportTML,
  Action.ImportTML,
  Action.UpdateTML,
  Action.PinboardInfo,
  Action.MakeACopy,
  Action.EditTitle,
  Action.RenameModalTitleDescription,
  Action.Pin,
  Action.SpotIQAnalyze,
  Action.Explore,
  Action.AddToFavorites,
  Action.AIHighlights,
];

export function Liveboard() {
  const location = useLocation();
  const expandViz = useExpandVisualizationStore((state) => state.show);

  const match = location.pathname.match(/\/pinboard\/([^/]+)/);
  const liveboardId = match ? match[1] : null;

  const handleCustomAction = React.useCallback(
    ({
      data: {
        id,
        embedAnswerData: { vizId },
      },
    }: OnCustomActionArgs) => {
      if (!liveboardId) {
        return;
      }
      if (id.toLowerCase() === EXPAND_ACTION.toLowerCase()) {
        expandViz({
          liveboardId,
          vizId,
        });
      }
    },
    [expandViz, liveboardId],
  );

  const runtimeFilters = useGetRuntimeFilters();

  const captureLiveboardView = useCaptureLiveboardView();
  React.useEffect(() => {
    if (!liveboardId) {
      return;
    }
    captureLiveboardView(liveboardId);
  }, [liveboardId, captureLiveboardView]);

  if (!liveboardId) {
    return null;
  }

  return (
    <>
      <LiveboardEmbed
        className="w-full h-full"
        liveboardId={liveboardId}
        showLiveboardTitle={true}
        showLiveboardDescription={true}
        hiddenActions={hiddenActions}
        onCustomAction={handleCustomAction}
        locale={LOCALE_OVERRIDE}
        runtimeFilters={runtimeFilters}
      />
      <ExpandVisualization />
    </>
  );
}
