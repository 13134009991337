import React from "react";
import { useGetBusinesses } from "../../hooks/useGetBusinesses";
import { useOnboardingProgressStore } from "../../stores/OnboardingProgressStore";
import { AllBusiness, Business, isAllBusiness } from "../../api";
import { useBusinessContext } from "../../hooks/useBusinessContext";
import { OnboardingProgress } from "./OnboardingProgress";
import { SKIP_ONBOARDING_PROGRESS_CHECK } from "../../constants";
import { useInitializeOnboardingProgress } from "../../hooks/useInitializeOnboardingProgress";
import { LoadingScreen } from "../../LoadingScreen";
import { customToast } from "../../utils/customToast";
import { useLogout } from "../../hooks/useLogout";
import { useSelf } from "../../hooks/useSelf";
import * as Sentry from "@sentry/react";

export function BusinessContext(props: React.PropsWithChildren) {
  const onboardingProgress = useInitializeOnboardingProgress();
  const businesses = useGetBusinesses();
  const self = useSelf();
  const {
    businessHasFinished: businessHasFinishedOnboarding,
    isHydrated: onboardingProgressIsHydrated,
    allHaveFinished: allBusinessesHaveFinishedOnboarding,
    atLeastOneHasFinished: atLeastOneBusinessHasFinished,
  } = useOnboardingProgressStore();
  const { setBusiness: saveBusinessContext } = useBusinessContext();

  const persistedBusinessContextId = self.data?.meta.norma.business_context;

  const { logout } = useLogout();
  React.useEffect(() => {
    if (!onboardingProgress.isError) {
      return;
    }
    customToast("Failed to get onboarding progress", "error");
    logout();
  }, [onboardingProgress.isError, logout]);

  const [showOnboardingProgress, setShowOnboardingProgress] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const changeBusinessContext = React.useCallback(
    (business: Business) => {
      setIsLoading(false);

      if (business.id === persistedBusinessContextId) {
        return;
      }

      saveBusinessContext(business.id);
    },
    [saveBusinessContext, persistedBusinessContextId],
  );

  React.useEffect(() => {
    if (!persistedBusinessContextId || !businesses.isSuccess || !onboardingProgressIsHydrated) {
      setIsLoading(true);
      return;
    }

    let selectedBusiness: Business;
    if (isAllBusiness(persistedBusinessContextId)) {
      selectedBusiness = AllBusiness;
    } else {
      const tmp = businesses.data.find((b) => b.id === persistedBusinessContextId);
      if (!tmp) {
        Sentry.captureEvent({
          message: "Couldn't find the business_context id inside the businesses list",
          level: "warning",
          extra: {
            businesses: businesses.data,
            persistedBusinessContext: persistedBusinessContextId,
          },
        });
        if (businesses.data.length === 0) {
          Sentry.captureEvent({
            message: "cannot select any business",
            level: "error",
          });
          return;
        }
        selectedBusiness = businesses.data[0];
      } else {
        selectedBusiness = tmp;
      }
    }

    if (!atLeastOneBusinessHasFinished) {
      setShowOnboardingProgress(true);
      changeBusinessContext(selectedBusiness);
      return;
    }

    setShowOnboardingProgress(false);

    if (
      mustSelectFirstNonPendingBusiness(
        selectedBusiness,
        allBusinessesHaveFinishedOnboarding,
        businessHasFinishedOnboarding,
      )
    ) {
      selectedBusiness = findFirstNonPendingBusiness(
        businesses.data,
        businessHasFinishedOnboarding,
      );
    }

    changeBusinessContext(selectedBusiness);
  }, [
    changeBusinessContext,
    businessHasFinishedOnboarding,
    allBusinessesHaveFinishedOnboarding,
    atLeastOneBusinessHasFinished,
    onboardingProgressIsHydrated,
    persistedBusinessContextId,
    saveBusinessContext,
    businesses.isSuccess,
    businesses.data,
  ]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (showOnboardingProgress && !SKIP_ONBOARDING_PROGRESS_CHECK) {
    return <OnboardingProgress />;
  }

  return props.children;
}

function findFirstNonPendingBusiness(
  businesses: Business[],
  businessHasFinishedOnboarding: (businessId: string) => boolean,
) {
  const business = businesses.find((b) => businessHasFinishedOnboarding(b.id));
  if (!business) {
    throw new Error(
      "No non-pending business found. This isn't expected since this function runs only after checking if at least one has finished",
    );
  }
  return business;
}

function mustSelectFirstNonPendingBusiness(
  selectedBusiness: Business,
  allBusinessesHaveFinishedOnboarding: boolean,
  businessHasFinishedOnboarding: (businessId: string) => boolean,
) {
  return (
    (isAllBusiness(selectedBusiness.id) && !allBusinessesHaveFinishedOnboarding) ||
    (!isAllBusiness(selectedBusiness.id) && !businessHasFinishedOnboarding(selectedBusiness.id))
  );
}
