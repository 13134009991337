import React from "react";
import { useMutation } from "@tanstack/react-query";
import { LoginDetails, LoginResponse, UNAUTHORIZED, login } from "../api";
import { useAccessToken } from "../Auth/useAccessToken";

export function useLogin() {
  const { setAccessToken } = useAccessToken();

  const [isWrongCredentials, setIsWrongCredentials] = React.useState(false);

  const loginMutation = useMutation({
    mutationFn: async (data: LoginDetails): Promise<LoginResponse | null> => {
      try {
        return await login({
          business_ref: data.business_ref,
          email: data.email,
          password: data.password,
        });
      } catch (err) {
        if (err === UNAUTHORIZED) {
          return null;
        }
        return Promise.reject(err);
      }
    },
    onMutate: () => {
      setIsWrongCredentials(false);
    },
    onSuccess: (resp) => {
      setIsWrongCredentials(!resp);
      if (!resp) {
        return;
      }
      setAccessToken(resp.token.token);
    },
  });

  return {
    isPending: loginMutation.isPending,
    isWrongCredentials,
    login: (data: LoginDetails) => loginMutation.mutateAsync(data),
  };
}
