import { useOutletContext } from "react-router-dom";
import { Card } from "../../../../components/Card";
import { OutletContext } from "../../components/OutletContext";

export function Welcome() {
  const { goToNextStage, cancel } = useOutletContext<OutletContext>();

  return (
    <Card
      cardTitle={"Welcome!"}
      Footer={
        <div className="flex gap-4">
          <button className="thoughtspot-cancel-button" onClick={cancel}>
            Cancel
          </button>
          <button className="thoughtspot-main-button" onClick={goToNextStage}>
            Continue
          </button>
        </div>
      }
      className="max-w-[50%] w-[660px]"
    >
      <div className="py-6 px-6 flex flex-col">
        <span className="content-text text-center py-2">
          You will see a list of all the users with the “<strong>Admin</strong>” authorities from
          the console. Choose which one you want to <strong>onboard</strong>. They will all receive
          an <strong>email</strong> with instructions on how to setup their account. They will have
          access to <strong>all the branches</strong> of your Organization.
        </span>
      </div>
    </Card>
  );
}
