import { User } from "../../api";
import { SvgIconType } from "../../svg";
import {
  isAnswersPath,
  isHomePath,
  isInsightsPath,
  isLiveboardPath,
  isSearchDataPath,
} from "../utils";

export type MenuButton = {
  name: string;
  path: string;
  icon: SvgIconType;
  label: string;
  isSelected: (pathname: string) => boolean;
  replace: boolean;
  className: (isSelected: boolean) => string;
};

const simpleButtonClassName = (
  isSelected: boolean,
) => `flex h-full space-x-2 px-6 items-center normaMenuItem border-b-2 hover:border-b-white
            ${isSelected ? "selectedNormaMenuItem border-b-white" : "border-b-transparent"}`;
const answerButtonClassName = (isSelected: boolean) =>
  `flex space-x-2 py-2 px-6 rounded-full ml-6 items-center border ${
    isSelected
      ? "text-white bg-[#777E8B4D] opacity-100 border-white"
      : "text-feintGray bg-[#777E8B4D] opacity-80 hover:text-backgroundGray hover:border-white"
  }`;

export function MENU_BUTTONS(currentUser?: User): MenuButton[] {
  const ret: MenuButton[] = [
    {
      name: "home",
      label: "Home",
      path: "/home",
      icon: "Home",
      replace: true,
      isSelected: isHomePath,
      className: simpleButtonClassName,
    },
    {
      name: "answers",
      label: "Answers",
      path: "/answers",
      icon: "Answers",
      replace: true,
      isSelected: isAnswersPath,
      className: simpleButtonClassName,
    },
    {
      name: "liveboards",
      label: "Liveboards",
      path: "/insights/pinboards",
      icon: "Liveboards",
      replace: true,
      isSelected: isLiveboardPath,
      className: simpleButtonClassName,
    },
    {
      name: "insights",
      label: "SpotIQ",
      path: "/insights",
      icon: "SpotIQ",
      replace: true,
      isSelected: isInsightsPath,
      className: simpleButtonClassName,
    },
  ];

  if (currentUser?.is_root) {
    ret.push({
      name: "settings",
      label: "Settings",
      path: "/settings",
      icon: "Settings",
      replace: false,
      isSelected: (pathname) => pathname.startsWith("/settings"),
      className: simpleButtonClassName,
    });
  }

  ret.push({
    name: "searchdata",
    label: "Search Data",
    path: "/answer",
    icon: "SearchData",
    replace: true,
    isSelected: isSearchDataPath,
    className: answerButtonClassName,
  });

  return ret;
}
