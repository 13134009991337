import { useQuery } from "@tanstack/react-query";
import { getAppMode } from "../api";
import { useAccessToken } from "../Auth/useAccessToken";

export function useAppMode() {
  const { accessToken } = useAccessToken();

  return useQuery({
    queryKey: ["appMode", accessToken],
    queryFn: async () => {
      return getAppMode(accessToken);
    },
    enabled: Boolean(accessToken),
    staleTime: Infinity,
  });
}
