import React from "react";
import { AccountRow } from "./AccountRow";
import * as api from "../api";
import { SvgIcon } from "../svg";

export type AccountsTableProps<T extends api.FoodicsAccount | api.UserAccount> = {
  accounts: T[];
  selectedAccounts: { [key: string]: boolean };
  onAccountSelect: (accountId: string, selected: boolean) => void;
  headers: string[];
  accountProperties: (keyof T)[];
  tableClassName?: string;
  isSelectable?: boolean;
  onSelectAll?: (selected: boolean) => void;
};

export function AccountsTable<T extends api.FoodicsAccount | api.UserAccount>({
  accounts,
  selectedAccounts,
  onAccountSelect,
  headers,
  accountProperties,
  tableClassName = "settings-users-table",
  isSelectable = true,
  onSelectAll,
}: AccountsTableProps<T>) {
  const allSelected =
    accounts.length > 0 && accounts.every((account) => selectedAccounts[account.id]);

  const handleSelectAll = () => {
    if (onSelectAll) {
      onSelectAll(!allSelected);
    }
  };

  return (
    <table className={tableClassName}>
      <thead>
        <tr>
          <th style={{ width: "5%", textAlign: "center" }}>
            <div
              onClick={handleSelectAll}
              style={{ cursor: "pointer", display: "flex", justifyContent: "center" }}
            >
              {allSelected ? <SvgIcon icon="CheckboxTicked" /> : <SvgIcon icon="CheckboxDefault" />}
            </div>
          </th>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {accounts.length > 0 ? (
          accounts.map((account) => (
            <AccountRow
              key={account.id}
              account={account}
              selected={Boolean(selectedAccounts[account.id])}
              onSelected={(selected) => onAccountSelect(account.id, selected)}
              accountProperties={accountProperties}
              isSelectable={isSelectable}
            />
          ))
        ) : (
          <tr>
            <td colSpan={headers.length + 1} className={"text-center content-label-subhead"}>
              No accounts available
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
