import { AppMode } from "../api";
import { changeAppMode } from "../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAppMode } from "./useAppMode";
import { useAccessToken } from "../Auth/useAccessToken";

export function useToggleAppMode() {
  const { data: mode } = useAppMode();
  const { accessToken } = useAccessToken();
  const queryClient = useQueryClient();

  const toggleAppModeMutation = useMutation({
    mutationFn: () => {
      const targetMode = mode === AppMode.LITE ? AppMode.PRO : AppMode.LITE;
      return changeAppMode(accessToken, targetMode);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["appMode"] }),
  });

  return {
    mode,
    toggleAppMode: toggleAppModeMutation.mutateAsync,
  };
}
