import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import * as api from "../../../../api";
import { useInitialOnboardingStore } from "../store";
import { OutletContext } from "../../components/OutletContext";
import { useCookies } from "react-cookie";
import { INITIAL_ONBOARDING_TOKEN_COOKIE } from "../../../../constants";
import { OnboardUsers } from "../../components/OnboardUsers";

export function OnboardUsersInNewOrganization() {
  const initialOnboarding = useInitialOnboardingStore();
  const [{ initial_onboarding_token }] = useCookies([INITIAL_ONBOARDING_TOKEN_COOKIE]);
  const { goToNextStage, goToPreviousStage } = useOutletContext<OutletContext>();

  const accounts = useQuery({
    queryKey: ["accounts", initial_onboarding_token],
    queryFn: async () => {
      return api.getInitialOnboardingFoodicsAccounts(initial_onboarding_token);
    },
    enabled: Boolean(initial_onboarding_token),
    refetchOnMount: false,
  });

  const navigate = useNavigate();

  return (
    <OnboardUsers
      mainMessage={"These users will have Access to All Branches of the Business"}
      secondaryMessage={
        "Below you see a list of users. Choose which ones you want to give access to the business."
      }
      accounts={accounts}
      selectedAccountIds={initialOnboarding.selectedAccountIds}
      setSelectedAccountIds={(accountIds) => initialOnboarding.setSelectedAccountIds(accountIds)}
      onCancel={() => {
        initialOnboarding.reset();
        return navigate("/");
      }}
      onGoToNextStage={goToNextStage}
      previousStageButton={{
        show: true,
        text: "Back",
        onClick: goToPreviousStage,
      }}
    />
  );
}
