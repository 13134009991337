import { BusinessAvatar } from "../components/QuickSettings/BusinessAvatar";
import { SvgIcon } from "../svg";
import { ContextMenu, ContextMenuOption } from "../components/ContextMenu";
import React, { Dispatch, SetStateAction } from "react";

export const BaseSettingsCell = {
  avatar: (value: string, extraClassName?: string) => (
    <div className="flex items-center">
      <div
        className={`table-element-avatar flex-shrink-0 w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center text-sm font-medium ${extraClassName}`}
      >
        {value[0].toUpperCase()}
      </div>
      <span className="content-label-subhead text-dark ml-2 truncate">{value}</span>
    </div>
  ),

  businessAvatar: (value: string) => (
    <div className="flex items-center gap-3">
      <BusinessAvatar
        businessDisplayName={value.toString()}
        className="bg-secondaryColor w-8 h-8"
      />
      <span>{value}</span>
    </div>
  ),

  status: (value: string) => <div className="first-letter:uppercase lowercase">{value}</div>,

  editIcon: (
    isHovered: boolean,
    showMenu: boolean,
    setShowMenu: Dispatch<SetStateAction<boolean>>,
    options?: ContextMenuOption[],
  ) => (
    <td className="edit-icon-cell">
      <div className="relative">
        <div
          className={`cursor-pointer ${isHovered || showMenu ? "visible" : "invisible"} ${
            showMenu ? "text-dark" : "text-light hover:text-dark"
          }`}
          onClick={() => setShowMenu(!showMenu)}
        >
          <div className="inline-block">
            <SvgIcon icon="Edit" />
          </div>
        </div>
        {options && <ContextMenu show={showMenu} setShow={setShowMenu} options={options} />}
      </div>
    </td>
  ),
};
