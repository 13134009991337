import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deactivateUser } from "../api";
import { useAccessToken } from "../Auth/useAccessToken";

export function useDeactivateUser() {
  const { accessToken } = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (args: { userId: string }) => {
      return deactivateUser(accessToken, args.userId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });
}
