import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./Auth";
import { LoadingScreen } from "./LoadingScreen";
import { ThoughtSpotStatus } from "./thoughtspot";

export function Protected(props: React.PropsWithChildren<{ thoughtspot?: boolean }>) {
  const { isAuthenticated, thoughtspotStatus } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return (
      <Navigate
        to="/login"
        replace
        state={{
          redirectAfterLogin: location,
        }}
      />
    );
  }

  if (props.thoughtspot) {
    if (thoughtspotStatus === ThoughtSpotStatus.Pending) {
      return <LoadingScreen />;
    }

    if (thoughtspotStatus === ThoughtSpotStatus.Failed) {
      // rely on the ErrorBoundary
      throw new Error("thoughtspot failed");
    }
  }

  return <>{props.children}</>;
}
