import React, { useMemo } from "react";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider as _QueryClientProvider,
} from "@tanstack/react-query";
import { useLogout } from "./hooks/useLogout";
import toast from "react-hot-toast";
import { ResponseError } from "./api";
import { throttle } from "@norma-bi/utils";

export function QueryClientProvider(props: React.PropsWithChildren) {
  const { logout } = useLogout();

  const showUnknownErrorToast = React.useMemo(
    () =>
      throttle(() => {
        toast.error("Something went wrong, please try again later");
      }, 1000),
    [],
  );

  const onError = React.useCallback(
    (err: Error) => {
      showUnknownErrorToast();

      if (err instanceof ResponseError) {
        console.error("Response error: ", err.response);
      }
    },
    [showUnknownErrorToast],
  );

  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          retry: (_, err) => {
            const respErr = err as Partial<{ response: { status: number } }>;
            if (respErr?.response?.status === 401 || respErr?.response?.status === 403) {
              logout();
              return false;
            }
            return true;
          },
        },
      },
      queryCache: new QueryCache({
        onError: (err, query) => {
          if (query.meta?.preventDefaultErrorHandling) {
            return;
          }
          return onError(err);
        },
      }),
      mutationCache: new MutationCache({
        onError: (err, _vars, _ctx, mutation) => {
          if (mutation.meta?.preventDefaultErrorHandling) {
            return;
          }
          return onError(err);
        },
      }),
    });
  }, [logout, onError]);

  return <_QueryClientProvider client={queryClient}>{props.children}</_QueryClientProvider>;
}
