import { SettingsEmailSchedulesTable } from "./SettingsEmailSchedulesTable";
import { PulseLoader } from "react-spinners";
import { useGetEmailSchedules } from "../../../hooks/useGetEmailSchedules";
import * as Sentry from "@sentry/react";

export function SettingsEmailSchedules() {
  const emailSchedules = useGetEmailSchedules();

  if (emailSchedules.isLoading) {
    return (
      <div className="flex justify-center items-center pt-20">
        <PulseLoader color="lightgray" loading={true} size={15} speedMultiplier={0.6} />
      </div>
    );
  }

  if (!emailSchedules.isSuccess) {
    Sentry.captureException(emailSchedules.error, {
      extra: { message: "emailSchedules.isSuccess is false" },
    });
    // TODO(yannis): show something
    return null;
  }

  if (emailSchedules.data.length === 0) {
    return (
      <div className="flex justify-center items-center">
        <span>No results</span>
      </div>
    );
  }

  return <SettingsEmailSchedulesTable emailSchedules={emailSchedules.data} />;
}
