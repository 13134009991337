import { addFavoriteLiveboard, removeFavoriteLiveboard } from "../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccessToken } from "../Auth/useAccessToken";
import { LiveboardsQueryKey } from "../hooks/useLiveboards";
import { useThoughtSpotToken } from "../thoughtspot";
import * as Sentry from "@sentry/react";

export function useFavoriteLiveboard() {
  const { accessToken } = useAccessToken();
  const { token: thoughtspotToken } = useThoughtSpotToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (args: { liveboardId: string; isFavorite: boolean }) => {
      if (!thoughtspotToken) {
        Sentry.captureEvent({
          message:
            "thoughtspot token should not be undefined when toggling the favorite state on a liveboard",
          level: "error",
        });
        return Promise.resolve();
      }
      if (args.isFavorite) {
        return removeFavoriteLiveboard(accessToken, thoughtspotToken, args.liveboardId);
      }
      return addFavoriteLiveboard(accessToken, thoughtspotToken, args.liveboardId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [LiveboardsQueryKey] });
    },
  });
}
