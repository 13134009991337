import React from "react";
import { NotFound } from "../pages/NotFound";
import { useSelf } from "../hooks/useSelf";
import { LoadingScreen } from "../LoadingScreen";

export function RequireRoot({ children }: React.PropsWithChildren) {
  const self = useSelf();

  if (!self.isSuccess) {
    return <LoadingScreen />;
  }

  if (!self.data.is_root) {
    return <NotFound />;
  }

  return children;
}
