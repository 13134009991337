import * as models from "./models";

export type PreMiddleware = (init?: RequestInit) => RequestInit;

// request is a drop-in replacement for fetch which adds error handling and pre-request middleware
export async function request(
  input: URL | RequestInfo,
  init?: RequestInit & { pre?: PreMiddleware[] },
  throwOnNotOkResponse = true,
): Promise<Response> {
  if (init?.pre) {
    init = init.pre.reduce((init, middleware) => middleware(init), init);
  }

  const response = await fetch(input, init);
  if (!response.ok && throwOnNotOkResponse) {
    throw new models.ResponseError(`Request to ${JSON.stringify(input)} failed`, response);
  }

  return response;
}

export const authMiddleware: (accessToken: string, thoughtspotToken?: string) => PreMiddleware = (
  accessToken,
  thoughtspotToken,
): PreMiddleware => {
  return (init) => {
    const headers = new Headers(init?.headers);
    headers.set("Authorization", `Bearer ${accessToken}`);
    if (thoughtspotToken) {
      headers.set("x-thoughtspot-token", thoughtspotToken);
    }
    return {
      ...init,
      headers,
    };
  };
};
