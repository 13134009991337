import React from "react";
import "./index.css";
import { Card } from "../../../../components/Card";
import { UseQueryResult } from "@tanstack/react-query";
import * as api from "../../../../api";
import { AccountsTable } from "../../../../components/AccountsTable";
import { PulseLoader } from "react-spinners";
import { SearchBox } from "../../../../components/SearchBox";

export type OnboardUsersProps = {
  mainMessage: string;
  secondaryMessage: string;
  accounts: UseQueryResult<api.FoodicsAccount[]>;
  selectedAccountIds: { [key: string]: boolean };
  setSelectedAccountIds: (accountIds: { [key: string]: boolean }) => void;
  skippable?: boolean;
  onGoToNextStage: () => void;
  previousStageButton:
    | { show: false }
    | {
        show: true;
        text: string;
        onClick: () => void;
      };
  onCancel: () => void;
};

export function OnboardUsers(props: OnboardUsersProps) {
  const [searchTerm, setSearchTerm] = React.useState("");

  const filteredAndSortedAccounts = React.useMemo(() => {
    if (!props.accounts.isSuccess) {
      return [];
    }
    return props.accounts.data
      .filter((account) => account.email.toLowerCase().includes(searchTerm.toLowerCase()))
      .sort((a, b) => {
        const branchesA = (a as api.FoodicsAccount).branches?.length || 0;
        const branchesB = (b as api.FoodicsAccount).branches?.length || 0;
        return branchesB - branchesA;
      });
  }, [props.accounts.isSuccess, props.accounts.data, searchTerm]);

  function onSelect(accountId: string) {
    if (props.selectedAccountIds[accountId]) {
      const copy = { ...props.selectedAccountIds };
      delete copy[accountId];
      props.setSelectedAccountIds(copy);
    } else {
      props.setSelectedAccountIds({ ...props.selectedAccountIds, [accountId]: true });
    }
  }

  function onSelectAll(selected: boolean) {
    if (selected) {
      const allAccountIds = filteredAndSortedAccounts.reduce(
        (final, acc) => {
          final[acc.id] = true;
          return final;
        },
        {} as Record<string, boolean>,
      );
      props.setSelectedAccountIds(allAccountIds);
    } else {
      props.setSelectedAccountIds({});
    }
  }

  const isLoading = props.accounts.isLoading;

  if (props.accounts.isError) {
    props.onCancel();
    return null;
  }

  const canGoToNextStep =
    props.skippable ||
    (props.accounts.isSuccess &&
      props.accounts.data.length > 0 &&
      Object.keys(props.selectedAccountIds).length > 0);

  return (
    <Card
      cardTitle={"Choose Users"}
      footerSeparator
      Footer={
        <div className="gap-4 flex items-center">
          {props.previousStageButton.show && (
            <button
              className="thoughtspot-cancel-button"
              onClick={props.previousStageButton.onClick}
            >
              {props.previousStageButton.text}
            </button>
          )}

          <button
            className="thoughtspot-main-button"
            onClick={() => props.onGoToNextStage()}
            disabled={!canGoToNextStep}
          >
            Next
            {Object.keys(props.selectedAccountIds).length > 0 && (
              <> ({Object.keys(props.selectedAccountIds).length})</>
            )}
          </button>
        </div>
      }
      className="w-full h-full max-w-[1557px]"
    >
      <div className="flex justify-between items-center py-6">
        <div className="flex flex-col">
          <span className="content-label">{props.mainMessage}</span>
          <span className="content-label-subhead">{props.secondaryMessage}</span>
        </div>

        <div>
          <SearchBox
            term={searchTerm}
            onTermChanged={setSearchTerm}
            placeholder="Search email"
            variant="darker"
          />
        </div>
      </div>

      <div className="overflow-auto flex-1">
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <PulseLoader color="lightgray" loading={true} size={15} speedMultiplier={0.6} />
          </div>
        ) : (
          <AccountsTable
            accounts={filteredAndSortedAccounts}
            selectedAccounts={props.selectedAccountIds}
            onAccountSelect={onSelect}
            headers={["Email", "Branches", "Roles"]}
            accountProperties={["email", "branches", "roles"]}
            tableClassName="choose-users-table"
            onSelectAll={onSelectAll}
          />
        )}
      </div>
    </Card>
  );
}
