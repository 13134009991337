import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { BaseSettingsTable } from "../../../components/BaseSettingsTable";
import { BaseSettingsCell } from "../../../components/BaseSettingsCell";
import { ContextMenu } from "../../../components/ContextMenu";
import { SvgIcon } from "../../../svg";
import { useUserContextMenuOptions } from "./useUserContextMenuOptions";
import { useSelf } from "../../../hooks/useSelf";
import { UsersSettingsTab } from "./tabs";
import clsx from "clsx";

export type UserRowModel = { id: string } & Partial<{
  email: string;
  name: string;
  roles: string[];
  branchesCount: number;
  businessesCount: number;
  is_root: boolean;
}>;

export type SettingsUsersTableProps = {
  accounts: UserRowModel[];
  tableClassName?: string;
  selectedTab: UsersSettingsTab;
};

const EditColumnComponent = ({
  row,
  selectedTab,
  isCurrentUserDemo,
}: {
  row: UserRowModel;
  selectedTab: UsersSettingsTab;
  isCurrentUserDemo: boolean;
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [showContextMenu, setShowContextMenu] = React.useState(false);

  const tmpUserContextOptions = useUserContextMenuOptions(selectedTab, row);
  const userContextOptions = React.useMemo(() => {
    if (isCurrentUserDemo) {
      return tmpUserContextOptions.map((o) => ({
        ...o,
        handler: async () => o.demoHandler(),
      }));
    }
    return tmpUserContextOptions;
  }, [isCurrentUserDemo, tmpUserContextOptions]);

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={clsx("cursor-pointer", {
          visible: isHovered || showContextMenu,
          invisible: !(isHovered || showContextMenu),
          "text-dark": showContextMenu,
          "text-light hover:text-dark": !showContextMenu,
        })}
        onClick={() => setShowContextMenu(!showContextMenu)}
      >
        <div className="inline-block">
          <SvgIcon icon="Edit" />
        </div>
      </div>
      <ContextMenu
        show={showContextMenu}
        setShow={setShowContextMenu}
        options={userContextOptions}
      />
    </div>
  );
};

export function SettingsUsersTable({
  accounts,
  tableClassName = "settings-table",
  selectedTab,
}: SettingsUsersTableProps) {
  const self = useSelf();
  const isCurrentUserDemo = self.data?.is_demo ?? false;

  const columns: ColumnDef<UserRowModel, keyof UserRowModel>[] = [
    {
      accessorKey: "email",
      header: "Email",
      cell: ({ getValue }) => BaseSettingsCell.avatar(getValue() as string),
    },
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "roles",
      header: "Console Roles",
      cell: ({ row }) => row.original.roles?.join(", ") || "-",
    },
    {
      accessorKey: "branchesCount",
      header: "Branches",
      cell: ({ getValue }) => getValue() || "-",
    },
    {
      accessorKey: "businessesCount",
      header: "Businesses",
      cell: ({ getValue }) => getValue() || "-",
    },
    {
      accessorKey: "is_root",
      header: "Permissions",
      cell: ({ getValue }) => (getValue() ? "Admin" : "Normal"),
    },
  ];

  return (
    <BaseSettingsTable
      data={accounts}
      columns={columns}
      tableClassName={tableClassName}
      renderEditColumn={(props) => (
        <EditColumnComponent
          row={props}
          selectedTab={selectedTab}
          isCurrentUserDemo={isCurrentUserDemo}
        />
      )}
    />
  );
}
