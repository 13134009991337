import React from "react";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { PostHogConfig } from "posthog-js";
import { useBusinessContext } from "./hooks/useBusinessContext";
import { isAllBusiness } from "./api";
import { useAppMode } from "./hooks/useAppMode";

export function WithPostHog({ children }: React.PropsWithChildren) {
  const disable =
    import.meta.env.VITE_DISABLE_POSTHOG_ON_HOSTS?.includes(window.location.host) ?? false;
  if (disable || !import.meta.env.VITE_POSTHOG_KEY) {
    return children;
  }
  const options: Partial<PostHogConfig> = {
    api_host: "https://eu.i.posthog.com",
    person_profiles: "identified_only",
    capture_pageview: false,
    capture_pageleave: false,
  };
  return (
    <PostHogProvider apiKey={import.meta.env.VITE_POSTHOG_KEY} options={options}>
      {children}
    </PostHogProvider>
  );
}

export function PostHogRegisterAppMode() {
  const posthog = usePostHog();
  const appMode = useAppMode();

  React.useEffect(() => {
    if (!appMode.isSuccess) {
      return;
    }

    posthog?.register({ app_mode: appMode.data });
  }, [posthog, appMode.isSuccess, appMode.data]);

  return null;
}

export function PostHogRegisterUserGroup() {
  const posthog = usePostHog();
  const { business, isLoading } = useBusinessContext();

  React.useEffect(() => {
    if (isLoading) {
      return;
    }

    posthog?.register({ business_context: business.id });
    if (!isAllBusiness(business.id)) {
      posthog?.group("business", business.id, {
        name: business.name,
      });
    }
  }, [posthog, business, isLoading]);

  return null;
}
