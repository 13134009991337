import React from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import * as api from "../../../../api";
import { useOnboardingStore } from "../store";
import { OutletContext } from "../../components/OutletContext";
import { OnboardUsers } from "../../components/OnboardUsers";
import { useAccessToken } from "../../../../Auth/useAccessToken";

export function OnboardUsersInExistingOrganization() {
  const { businessId } = useParams<{ businessId: string }>();
  const onboardingStore = useOnboardingStore();
  const { accessToken } = useAccessToken();
  const { goToNextStage, cancel } = useOutletContext<OutletContext>();

  const accounts = useQuery({
    queryKey: ["accounts", businessId],
    queryFn: async () => {
      return api.getInactiveFoodicsAccounts(accessToken, businessId!);
    },
    enabled: Boolean(businessId) && businessId !== "",
  });

  return (
    <OnboardUsers
      mainMessage={"Users from New Business"}
      secondaryMessage={
        "The list of users you see below will have access only to the new Business."
      }
      accounts={accounts}
      selectedAccountIds={onboardingStore.selectedAccountIds}
      setSelectedAccountIds={onboardingStore.setSelectedAccountIds}
      onGoToNextStage={goToNextStage}
      previousStageButton={{ show: false }}
      onCancel={cancel}
      skippable
    />
  );
}
