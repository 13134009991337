import { AppMode } from "../api";
import { ProLayout } from "../ProLayout";
import { LiteLayout } from "../LiteLayout";
import { useAppMode } from "../hooks/useAppMode";
import { LoadingScreen } from "../LoadingScreen";
import { Logout } from "../Logout";

export function DynamicLayout() {
  const { data: mode, isLoading } = useAppMode();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      {mode === AppMode.PRO ? <ProLayout /> : <LiteLayout />}
      <Logout />
    </>
  );
}
