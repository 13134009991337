import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../api";
import { useAccessToken } from "../Auth/useAccessToken";

export function useResetPassword() {
  const { setAccessToken } = useAccessToken();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (args: { token: string; newPassword: string }) => {
      return resetPassword({
        newPassword: args.newPassword,
        resetPasswordToken: args.token,
      });
    },
    onSuccess: ({ token: { token } }) => {
      setAccessToken(token);
      navigate("/");
    },
  });
}
