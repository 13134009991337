import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getStatus } from "../api";
import { LoadingScreen } from "../LoadingScreen";
import { Maintenance } from "../pages/Maintenance";

export function MaintenanceGuard({ children }: React.PropsWithChildren) {
  const SKIP = import.meta.env.VITE_SKIP_MAINTENANCE_CHECK;
  const status = useQuery({
    queryKey: ["status"],
    queryFn: getStatus,
    refetchInterval: 60000,
    enabled: !SKIP,
  });

  if (SKIP) {
    return children;
  }

  if (status.isLoading || !status.isSuccess) {
    return <LoadingScreen />;
  }

  if (!status.data.available) {
    return <Maintenance />;
  }

  return children;
}
