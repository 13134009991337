import React from "react";
import {
  OnboardingProgressEventEmitter,
  OnboardingProgressEventListener,
  ProgressEventData,
} from "../api";
import { useOnboardingProgressStore } from "../stores/OnboardingProgressStore";
import { useAccessToken } from "../Auth/useAccessToken";

function allHaveFinished(progress: ProgressEventData) {
  return progress.length > 0 && progress.every((p) => p.current === p.total);
}

export function useInitializeOnboardingProgress() {
  const { accessToken } = useAccessToken();
  const [isError, setIsError] = React.useState(false);

  const storeSetProgress = useOnboardingProgressStore((state) => state.setProgress);

  const onboardingProgressEventEmitter = React.useMemo(() => {
    storeSetProgress([]);
    if (!accessToken) {
      return null;
    }
    const emitter = new OnboardingProgressEventEmitter(accessToken);
    emitter.addErrorListener(() => {
      setIsError(true);
    });
    return emitter;
  }, [accessToken, storeSetProgress]);

  React.useEffect(() => {
    if (!onboardingProgressEventEmitter || isError) {
      return;
    }
    const listener: OnboardingProgressEventListener = (data) => {
      storeSetProgress(data);
      if (allHaveFinished(data)) {
        onboardingProgressEventEmitter.removeEventListener(listener);
      }
    };
    onboardingProgressEventEmitter.addEventListener(listener);
    return () => {
      onboardingProgressEventEmitter.removeEventListener(listener);
    };
  }, [onboardingProgressEventEmitter, storeSetProgress, isError]);

  return { isError };
}
