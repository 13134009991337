import { useQuery } from "@tanstack/react-query";
import { getBusinesses } from "../api";
import { useAccessToken } from "../Auth/useAccessToken";

export function useGetBusinesses() {
  const { accessToken } = useAccessToken();

  return useQuery({
    queryKey: ["businesses", accessToken],
    queryFn: () => getBusinesses(accessToken),
    enabled: Boolean(accessToken),
    staleTime: Infinity,
  });
}
