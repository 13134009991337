export const BASE_URL = import.meta.env.VITE_API_BASE_URL;
if (!BASE_URL) {
  throw new Error("Missing BASE_URL");
}

export const SKIP_ONBOARDING_PROGRESS_CHECK =
  import.meta.env.VITE_SKIP_ONBOARDING_PROGRESS_CHECK ?? false;

export const INITIAL_ONBOARDING_TOKEN_COOKIE = "initial_onboarding_token";

// This should be added to the state whenever a route change which should not trigger an embedding navigation happens
export const ROUTE_STATE_EMBED_SHOULD_IGNORE_ROUTE_CHANGE = "embed_should_ignore_route_change";
