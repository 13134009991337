import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Stage, StagesIndicator } from "./StagesIndicator";
import { OutletContext } from "./OutletContext";
import { UseMutationResult } from "@tanstack/react-query";

export type OnboardingOrchestratorAction = {
  navigateTo: string;
};

export type OnboardingOrchestratorProps = {
  stages: Stage[];
  finishMutation: UseMutationResult<void, Error, void>;
  cleanup?: () => void;
  onDone: OnboardingOrchestratorAction;
  onCancel: OnboardingOrchestratorAction;
  onError: OnboardingOrchestratorAction;
};

export function OnboardingOrchestrator(props: OnboardingOrchestratorProps) {
  const navigate = useNavigate();
  const location = useLocation();

  async function goToNextStage() {
    const currentStage = props.stages.findIndex((s) => location.pathname.includes(s.path));
    if (currentStage === -1) throw new Error("stages are known on build time, this is unexpected");

    const isLastStage = currentStage === props.stages.length - 1;
    if (isLastStage) {
      if (props.finishMutation.isError) {
        props.cleanup?.();
        return navigate(props.onError.navigateTo, { replace: true });
      }
      await props.finishMutation.mutateAsync(undefined, {
        onSuccess: () => {
          props.cleanup?.();
          return navigate(props.onDone.navigateTo, { replace: true });
        },
      });
      return;
    }

    // currentStage is guaranteed to be < STAGES.length
    navigate(props.stages[currentStage + 1].path);
  }

  async function goToPreviousStage() {
    const currentStage = props.stages.findIndex((s) => location.pathname.includes(s.path));
    if (currentStage === -1) throw new Error("stages are known on build time, this is unexpected");

    const isFirstStage = currentStage === 0;
    if (isFirstStage) throw new Error("The first stage shouldn't have a previous stage");

    navigate(-1);
  }

  function cancel() {
    props.cleanup?.();
    navigate(props.onCancel.navigateTo, { replace: true });
  }

  return (
    <div className={"h-full w-full bg-[#EAEDF2] flex flex-col items-center"}>
      <div className="pt-10 pb-5 w-full flex justify-center items-center">
        <StagesIndicator stages={props.stages} />
      </div>

      {/*
      min-h-0 ensures that the container will shrink when necessary to make sure that it takes
      ONLY as much space as is available and not more
      */}
      <div className="w-full flex-1 flex justify-center items-center px-10 mb-10 min-h-0">
        <Outlet
          context={
            {
              goToNextStage,
              goToPreviousStage,
              isFinishPending: props.finishMutation.isPending,
              isFinishError: props.finishMutation.isError,
              cancel,
            } satisfies OutletContext
          }
        />
      </div>
    </div>
  );
}
