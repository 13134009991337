import { useQuery } from "@tanstack/react-query";
import { getSelf } from "../api";
import { useAccessToken } from "../Auth/useAccessToken";

export function useSelf() {
  const { accessToken } = useAccessToken();

  return useQuery({
    queryKey: ["self", accessToken],
    queryFn: async () => {
      return getSelf(accessToken);
    },
    enabled: Boolean(accessToken),
  });
}
