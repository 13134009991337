import { useQuery } from "@tanstack/react-query";
import { getEmailSchedules } from "../api";
import { useAccessToken } from "../Auth/useAccessToken";
import { useThoughtSpotToken } from "../thoughtspot";

export const EmailSchedulesQueryKey = "emailSchedules" as const;

export function useGetEmailSchedules() {
  const { accessToken } = useAccessToken();
  const { token: thoughtspotToken } = useThoughtSpotToken();

  return useQuery({
    queryKey: [EmailSchedulesQueryKey, accessToken, thoughtspotToken],
    queryFn: () => getEmailSchedules(accessToken, thoughtspotToken!),
    enabled: Boolean(accessToken) && Boolean(thoughtspotToken),
    staleTime: Infinity,
  });
}
