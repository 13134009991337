import React from "react";
import { AllBusiness, Business, isAllBusiness, saveBusinessContext } from "../api";
import { useSelf } from "./useSelf";
import { useGetBusinesses } from "./useGetBusinesses";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccessToken } from "../Auth/useAccessToken";

export type BusinessContext = {
  setBusiness: (businessId: string) => Promise<void>;
} & (
  | {
      business: Business;
      isLoading: false;
    }
  | {
      business: undefined;
      isLoading: true;
    }
);

export function useBusinessContext(): BusinessContext {
  const { accessToken } = useAccessToken();
  const self = useSelf();
  const businesses = useGetBusinesses();
  const queryClient = useQueryClient();

  const changeSelectedBusiness = useMutation({
    mutationFn: (businessId: string) => {
      return saveBusinessContext(accessToken, businessId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["self"],
      });
    },
  });

  const businessContext = self.data?.meta.norma.business_context;
  const setBusiness = changeSelectedBusiness.mutateAsync;

  return React.useMemo<BusinessContext>(() => {
    if (!businesses.isSuccess || !businessContext) {
      return {
        business: undefined,
        isLoading: true,
        setBusiness,
      };
    }
    if (isAllBusiness(businessContext)) {
      return {
        business: AllBusiness,
        isLoading: false,
        setBusiness,
      };
    }
    const business = businesses.data?.find((b) => b.id === businessContext);
    if (!business) {
      return {
        business: undefined,
        isLoading: true,
        setBusiness,
      };
    }
    return {
      business,
      isLoading: false,
      setBusiness,
    };
  }, [businesses.isSuccess, businesses.data, businessContext, setBusiness]);
}
