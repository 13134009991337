import React from "react";
import * as api from "../api";
import { SvgIcon } from "../svg";

export type AccountRowProps<T extends api.FoodicsAccount | api.UserAccount> = {
  account: T;
  selected: boolean;
  onSelected: (selected: boolean) => void;
  accountProperties: (keyof T)[];
  isSelectable: boolean;
};

export function AccountRow<T extends api.FoodicsAccount | api.UserAccount>({
  account,
  selected,
  onSelected,
  accountProperties,
  isSelectable,
}: AccountRowProps<T>) {
  const [isHovered, setIsHovered] = React.useState(false);

  const isFoodicsAccount = (
    account: api.FoodicsAccount | api.UserAccount,
  ): account is api.FoodicsAccount => {
    return (
      (account as api.FoodicsAccount).branches !== undefined &&
      (account as api.FoodicsAccount).roles !== undefined
    );
  };

  const handleRowClick = () => {
    if (isSelectable) {
      onSelected(!selected);
    }
  };

  const showCheckbox = selected || (isHovered && isSelectable);

  return (
    <tr
      className={`${selected || isHovered ? "bg-lightGray" : ""}`}
      onClick={handleRowClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <td>
        <div className="flex items-center justify-center">
          {showCheckbox && (
            <div style={{ cursor: "pointer" }}>
              {selected ? <SvgIcon icon="CheckboxTicked" /> : <SvgIcon icon="CheckboxDefault" />}
            </div>
          )}
        </div>
      </td>
      {accountProperties.map((property, index) => {
        if (property === "email") {
          return (
            <td key={index}>
              <div className="flex items-center gap-2">
                <div
                  className={`size-8 min-w-8 min-h-8 text-white capitalize text-center content-center rounded-full ${
                    "is_owner" in account && account.is_owner ? "bg-[#7D55C7]" : "bg-[#74D1EA]"
                  }`}
                >
                  {account.email[0]}
                </div>
                <span className="font-medium text-sm overflow-hidden break-words">
                  {account.email}
                </span>
              </div>
            </td>
          );
        }
        if (property === "branches" && isFoodicsAccount(account)) {
          return (
            <td key={index}>
              <span className="font-normal text-sm">{account.branches.length} Branches</span>
            </td>
          );
        }
        if (property === "roles") {
          const roles = isFoodicsAccount(account)
            ? account.roles.map((role) => role.name).join(", ")
            : account.meta?.foodics?.roles.map((role) => role.name).join(", ");
          return (
            <td key={index}>
              <span className="font-normal text-sm">{roles}</span>
            </td>
          );
        }
        return (
          <td key={index}>
            <span className="font-normal text-sm">{account[property] as React.ReactNode}</span>
          </td>
        );
      })}
    </tr>
  );
}
