import React from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

export function PostOnboardingRedirectsHandler() {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  if (location.pathname.endsWith("/multi-business/success")) {
    const businessId = searchParams.get("business-id");
    if (businessId === "") {
      console.error(
        "Was redirected to /multi-business/success without business-id query param. Redirecting to /settings/users",
      );
      return <Navigate to={"/settings/users"} replace />;
    }
    return <Navigate to={`/onboarding/${businessId}`} replace />;
  }

  if (location.pathname.endsWith("/multi-business/already-installed")) {
    let inAnotherOrg = searchParams.get("in-another-org");
    if (inAnotherOrg !== "true" && inAnotherOrg !== "false") {
      console.error(
        "Was redirected to /multi-business/already-installed without in-another-org query param. Redirect to /settings/business?in-another-org=false",
      );
      inAnotherOrg = "false";
    }
    return <Navigate to={`/settings/businesses?in-another-org=${inAnotherOrg}`} replace />;
  }

  if (location.pathname.endsWith("/already-installed")) {
    const adminEmails = searchParams.getAll("admin-emails");
    return <Navigate to={"/login?already-installed"} state={{ adminEmails }} replace />;
  }

  throw new Error(`unknown post onboarding redirect: ${location.pathname + location.search}`);
}
