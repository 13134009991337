import { useLocation, useNavigate } from "react-router-dom";

export type Stage = {
  path: string;
};

export type StagesIndicatorProps = {
  stages: Stage[];
};

export function StagesIndicator(props: StagesIndicatorProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const currentStageIndex = props.stages.findIndex((s) => location.pathname.includes(s.path));

  const handleStageClick = (index: number) => {
    if (index < currentStageIndex) {
      navigate(props.stages[index].path);
    }
  };

  return (
    <div className="w-full flex gap-10 justify-center">
      {props.stages.map((s, i) => {
        const isActive = i === currentStageIndex;
        const isPast = i < currentStageIndex;
        return (
          <div
            key={s.path}
            className={`w-[205px] h-10 flex flex-row justify-center items-center border-b-2 border-[#1D232F]
                        ${isActive ? "bg-lightGray text-[#1D232F]" : "bg-transparent text-[#777E8B]"}
                        ${isPast ? "cursor-pointer" : "cursor-default"}`}
            onClick={() => handleStageClick(i)}
          >
            <span>Stage {i + 1}</span>
          </div>
        );
      })}
    </div>
  );
}
