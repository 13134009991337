import { useMutation, useQueryClient } from "@tanstack/react-query";
import { onboardFoodicsAccounts } from "../api";
import { useAccessToken } from "../Auth/useAccessToken";

export function useOnboardFoodicsAccounts() {
  const { accessToken } = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (args: { businessId: string; accountIds: string[] }) => {
      return onboardFoodicsAccounts(accessToken, args.businessId, args.accountIds);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["users"] });
      await queryClient.invalidateQueries({ queryKey: ["accounts"] });
    },
  });
}
