import { useMutation, useQueryClient } from "@tanstack/react-query";
import { resendInvitations } from "../api";
import { useAccessToken } from "../Auth/useAccessToken";

export function useResendInvitation() {
  const { accessToken } = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userId: string) => resendInvitations(accessToken, [userId]),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });
}
