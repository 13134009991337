import { Outlet } from "react-router-dom";
import { AuthProvider } from "./Auth";
import { MobileDeviceGuard } from "./guards/MobileDeviceGuard";
import { PostHogRegisterAppMode, PostHogRegisterUserGroup, WithPostHog } from "./posthog";

export function Root() {
  return (
    <WithPostHog>
      <MobileDeviceGuard>
        <AuthProvider>
          <Outlet />
        </AuthProvider>
      </MobileDeviceGuard>

      <PostHogRegisterUserGroup />
      <PostHogRegisterAppMode />
    </WithPostHog>
  );
}
