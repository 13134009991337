import { fetchEventSource } from "@microsoft/fetch-event-source";
import { BASE_URL } from "../constants";
import { authMiddleware } from "./request";

export type ErrorEventData = {
  type: "error";
};

function isErrorEventData(data: Record<string, unknown>): data is ErrorEventData {
  return data.type === "error";
}

export type OnboardingProgressEventListener = (event: ProgressEventData) => void;
export type ErrorEventListener = (event: ErrorEventData) => void;

export type ProgressEventData = {
  business_id: string;
  current: number;
  total: number;
}[];

function isProgressEventData(data: Record<string, unknown>[]): data is ProgressEventData {
  return (
    Array.isArray(data) &&
    data.every((d) => {
      return (
        typeof d.current === "number" &&
        typeof d.total === "number" &&
        typeof d.business_id === "string"
      );
    })
  );
}
export class OnboardingProgressEventEmitter {
  private accessToken: string;

  private streamCtrl: AbortController | null = null;
  private listeners: OnboardingProgressEventListener[] = [];
  private errorListeners: ErrorEventListener[] = [];

  constructor(accessToken: string) {
    this.accessToken = accessToken;
  }

  addEventListener(cb: OnboardingProgressEventListener): void {
    this.listeners.push(cb);
    if (this.listeners.length === 1) {
      this.openStream();
    }
  }

  // this is safe to be called multiple times
  removeEventListener(cb: OnboardingProgressEventListener): void {
    this.listeners = this.listeners.filter((listener) => listener !== cb);
    if (this.listeners.length === 0) {
      this.closeStream();
    }
  }

  addErrorListener(cb: ErrorEventListener): void {
    this.errorListeners.push(cb);
  }

  removeErrorListener(cb: ErrorEventListener): void {
    this.errorListeners = this.errorListeners.filter((listener) => listener !== cb);
  }

  private openStream() {
    if (this.streamCtrl) {
      throw new Error("onboarding progress stream is already open; this is Unexpected");
    }
    this.streamCtrl = new AbortController();

    const authenticatedFetch: typeof fetch = (input, init) => {
      return fetch(input, authMiddleware(this.accessToken)(init));
    };

    fetchEventSource(`${BASE_URL}/v2/sub/progress`, {
      fetch: authenticatedFetch,
      onmessage: (event) => {
        const resp = JSON.parse(event.data);
        if (isErrorEventData(resp)) {
          this.broadcastError(resp);
          return;
        }
        if (!Array.isArray(resp) || !isProgressEventData(resp)) {
          this.broadcastError({ type: "error" });
          console.error("Unexpected progress event data", event.data);
          return;
        }
        this.broadcast(resp);
      },
      onerror: (error) => {
        this.broadcastError({ type: "error" });
        console.error(error);
      },
    });
  }

  private closeStream() {
    if (this.streamCtrl) {
      this.streamCtrl.abort();
      this.streamCtrl = null;
    }
  }

  private broadcast(data: ProgressEventData) {
    this.listeners.forEach((cb) => cb(data));
  }

  private broadcastError(data: ErrorEventData) {
    this.errorListeners.forEach((cb) => cb(data));
  }
}
