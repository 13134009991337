import React from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getThoughtspotToken } from "../api";
import { useAccessToken } from "../Auth/useAccessToken";
import { useTokenStore } from "./TokenStore";

export function useThoughtSpotToken() {
  const queryClient = useQueryClient();
  const { accessToken } = useAccessToken();

  const { token: savedToken, setToken: saveToken } = useTokenStore();

  const queryKey = React.useMemo(() => ["thoughtSpotToken", accessToken], [accessToken]);

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      return getThoughtspotToken(accessToken);
    },
    // don't run the query if we already have a token
    enabled: Boolean(accessToken) && !savedToken,
  });

  React.useEffect(() => {
    if (!query.isSuccess) {
      return;
    }
    saveToken(query.data);
  }, [query.isSuccess, query.data, saveToken]);

  const invalidateCurrentToken = React.useCallback(() => {
    saveToken(null);
    queryClient.invalidateQueries({ queryKey });
  }, [saveToken, queryClient, queryKey]);

  return { token: savedToken, invalidateCurrentToken };
}
