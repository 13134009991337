import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./Auth";

export function Unprotected(props: React.PropsWithChildren<{ thoughtspot?: boolean }>) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return props.children;
}
