import React from "react";
import { SvgIcon } from "../svg";
import clsx from "clsx";

export type SearchBarProps = {
  searchInput: string;
  setSearchInput: (input: string) => void;
};

export const SearchBar: React.FC<SearchBarProps> = ({ searchInput, setSearchInput }) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [isExpanded, setIsExpanded] = React.useState(false);

  function onClick() {
    setIsExpanded((curr) => !curr);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  const onClickCancel = React.useCallback(() => {
    setIsExpanded(false);
    setSearchInput("");
  }, [setIsExpanded, setSearchInput]);

  React.useEffect(() => {
    const el = containerRef.current;

    function clickAwayHandler(evt: MouseEvent) {
      if (!el) {
        return;
      }
      if (!el.contains(evt.target as Node)) {
        setIsExpanded(false);
      }
    }

    document.addEventListener("click", clickAwayHandler);
    return () => document.removeEventListener("click", clickAwayHandler);
  }, [setIsExpanded]);

  return (
    <div ref={containerRef} className={clsx("expandable-searchbar", isExpanded && "expanded")}>
      <button className="search-icon" onClick={onClick}>
        <SvgIcon icon="Search" />
      </button>

      <input
        ref={inputRef}
        type="text"
        placeholder="Search"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />

      <button className="x" onClick={onClickCancel}>
        <SvgIcon icon="Cross" />
      </button>
    </div>
  );
};
