import * as api from "../../../api";

export function getDemoUsers(): ReturnType<typeof api.getUsers> {
  return Promise.resolve(users);
}

export function getDemoInactiveFoodicsAccounts(): ReturnType<
  typeof api.getInactiveFoodicsAccounts
> {
  return Promise.resolve(inactiveFoodicsAccounts);
}

const demoOrg: api.Organization = {
  id: "demo",
  name: "Demo organization",
};

const users: api.User[] = [
  {
    id: "1",
    organization: demoOrg,
    name: "John Doe",
    identities: [{ email: "john.doe@example.com", business_id: "" }],
    avatar: "",
    should_change_password: false,
    is_active: true,
    meta: {
      foodics: {
        roles: ["Cashier"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: false,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "2",
    organization: demoOrg,
    name: "Jane Smith",
    identities: [{ email: "jane.smith@example.com", business_id: "" }],
    avatar: "",
    should_change_password: true,
    is_active: false,
    meta: {
      foodics: {
        roles: ["Waiter"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: true,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "3",
    organization: demoOrg,
    name: "Bob Johnson",
    identities: [{ email: "bob.johnson@example.com", business_id: "" }],
    avatar: "",
    should_change_password: false,
    is_active: true,
    meta: {
      foodics: {
        roles: ["User"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: false,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "4",
    organization: demoOrg,
    name: "Alice Williams",
    identities: [{ email: "alice.williams@example.com", business_id: "" }],
    avatar: "",
    should_change_password: true,
    is_active: true,
    meta: {
      foodics: {
        roles: ["Cashier"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: false,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "5",
    organization: demoOrg,
    name: "Tom Brown",
    identities: [{ email: "tom.brown@example.com", business_id: "" }],
    avatar: "",
    should_change_password: false,
    is_active: false,
    meta: {
      foodics: {
        roles: ["Waiter"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: true,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "6",
    organization: demoOrg,
    name: "Emily Davis",
    identities: [{ email: "emily.davis@example.com", business_id: "" }],
    avatar: "",
    should_change_password: true,
    is_active: true,
    meta: {
      foodics: {
        roles: ["User"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: false,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "7",
    organization: demoOrg,
    name: "James Miller",
    identities: [{ email: "james.miller@example.com", business_id: "" }],
    avatar: "",
    should_change_password: true,
    is_active: true,
    meta: {
      foodics: {
        roles: ["Cashier"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: true,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "8",
    organization: demoOrg,
    name: "Sara Wilson",
    identities: [{ email: "sara.wilson@example.com", business_id: "" }],
    avatar: "",
    should_change_password: false,
    is_active: false,
    meta: {
      foodics: {
        roles: ["Waiter"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: false,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "9",
    organization: demoOrg,
    name: "Chris Anderson",
    identities: [{ email: "chris.anderson@example.com", business_id: "" }],
    avatar: "",
    should_change_password: true,
    is_active: true,
    meta: {
      foodics: {
        roles: ["User"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: true,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "10",
    organization: demoOrg,
    name: "Michael Clark",
    identities: [{ email: "michael.clark@example.com", business_id: "" }],
    avatar: "",
    should_change_password: false,
    is_active: false,
    meta: {
      foodics: {
        roles: ["Cashier"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: false,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "11",
    organization: demoOrg,
    name: "Patricia Lewis",
    identities: [{ email: "patricia.lewis@example.com", business_id: "" }],
    avatar: "",
    should_change_password: true,
    is_active: true,
    meta: {
      foodics: {
        roles: ["Waiter"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: true,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "12",
    organization: demoOrg,
    name: "Linda Martinez",
    identities: [{ email: "linda.martinez@example.com", business_id: "" }],
    avatar: "",
    should_change_password: false,
    is_active: true,
    meta: {
      foodics: {
        roles: ["User"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: false,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "13",
    organization: demoOrg,
    name: "Daniel Hall",
    identities: [{ email: "daniel.hall@example.com", business_id: "" }],
    avatar: "",
    should_change_password: true,
    is_active: false,
    meta: {
      foodics: {
        roles: ["Cashier"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: true,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "14",
    organization: demoOrg,
    name: "Elizabeth Scott",
    identities: [{ email: "elizabeth.scott@example.com", business_id: "" }],
    avatar: "",
    should_change_password: false,
    is_active: true,
    meta: {
      foodics: {
        roles: ["Waiter"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: false,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "15",
    organization: demoOrg,
    name: "Paul Walker",
    identities: [{ email: "paul.walker@example.com", business_id: "" }],
    avatar: "",
    should_change_password: true,
    is_active: false,
    meta: {
      foodics: {
        roles: ["User"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: true,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "16",
    organization: demoOrg,
    name: "Nancy Lee",
    identities: [{ email: "nancy.lee@example.com", business_id: "" }],
    avatar: "",
    should_change_password: false,
    is_active: true,
    meta: {
      foodics: {
        roles: ["Cashier"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: false,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "17",
    organization: demoOrg,
    name: "Mark King",
    identities: [{ email: "mark.king@example.com", business_id: "" }],
    avatar: "",
    should_change_password: true,
    is_active: true,
    meta: {
      foodics: {
        roles: ["Waiter"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: true,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "18",
    organization: demoOrg,
    name: "Steven Young",
    identities: [{ email: "steven.young@example.com", business_id: "" }],
    avatar: "",
    should_change_password: false,
    is_active: false,
    meta: {
      foodics: {
        roles: ["User"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: false,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "19",
    organization: demoOrg,
    name: "Jennifer Adams",
    identities: [{ email: "jennifer.adams@example.com", business_id: "" }],
    avatar: "",
    should_change_password: true,
    is_active: true,
    meta: {
      foodics: {
        roles: ["Cashier"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: true,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
  {
    id: "20",
    organization: demoOrg,
    name: "Susan Perez",
    identities: [{ email: "susan.perez@example.com", business_id: "" }],
    avatar: "",
    should_change_password: false,
    is_active: true,
    meta: {
      foodics: {
        roles: ["Waiter"],
      },
      norma: {
        enterprise_web_mode: "lite",
        liveboard_categories_ordered: null,
        business_context: "",
      },
    },
    is_root: false,
    organization_id: demoOrg.id,
    businesses_count: 2,
    venues_count: 20,
  },
];

function generateEmptyBranches(): api.FoodicsBranch[] {
  return Array.from({ length: 20 }, (_, index) => ({
    id: `branch-${index + 1}`,
    name: "",
  }));
}

const inactiveFoodicsAccounts: api.FoodicsAccount[] = [
  {
    id: "1",
    name: "John Doe",
    email: "john.doe@foodics.com",
    branches: generateEmptyBranches(),
    roles: [{ id: "1", name: "Cashier" }],
    is_owner: false,
  },
  {
    id: "2",
    name: "Jane Smith",
    email: "jane.smith@foodics.com",
    branches: generateEmptyBranches(),
    roles: [{ id: "2", name: "Waiter" }],
    is_owner: false,
  },
  {
    id: "3",
    name: "Bob Johnson",
    email: "bob.johnson@foodics.com",
    branches: generateEmptyBranches(),
    roles: [{ id: "3", name: "User" }],
    is_owner: false,
  },
  {
    id: "4",
    name: "Alice Williams",
    email: "alice.williams@foodics.com",
    branches: generateEmptyBranches(),
    roles: [{ id: "4", name: "Cashier" }],
    is_owner: false,
  },
  {
    id: "5",
    name: "Tom Brown",
    email: "tom.brown@foodics.com",
    branches: generateEmptyBranches(),
    roles: [{ id: "5", name: "Waiter" }],
    is_owner: false,
  },
  {
    id: "6",
    name: "Emily Davis",
    email: "emily.davis@foodics.com",
    branches: generateEmptyBranches(),
    roles: [{ id: "6", name: "User" }],
    is_owner: false,
  },
  {
    id: "7",
    name: "James Miller",
    email: "james.miller@foodics.com",
    branches: generateEmptyBranches(),
    roles: [{ id: "7", name: "Cashier" }],
    is_owner: false,
  },
  {
    id: "8",
    name: "Sara Wilson",
    email: "sara.wilson@foodics.com",
    branches: generateEmptyBranches(),
    roles: [{ id: "8", name: "Waiter" }],
    is_owner: false,
  },
  {
    id: "9",
    name: "Chris Anderson",
    email: "chris.anderson@foodics.com",
    branches: generateEmptyBranches(),
    roles: [{ id: "9", name: "User" }],
    is_owner: false,
  },
  {
    id: "10",
    name: "Michael Clark",
    email: "michael.clark@foodics.com",
    branches: generateEmptyBranches(),
    roles: [{ id: "10", name: "Cashier" }],
    is_owner: false,
  },
];
