import React from "react";
import { ThoughtSpotStatus } from "../thoughtspot";

export type AuthContext = {
  isAuthenticated: boolean;
  thoughtspotStatus: ThoughtSpotStatus;
};

export const authContext = React.createContext<AuthContext>({
  isAuthenticated: false,
  thoughtspotStatus: ThoughtSpotStatus.Pending,
});
