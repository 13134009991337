import { ColumnDef } from "@tanstack/react-table";
import { EmailSchedule } from "../../../api";
import { useNavigate } from "react-router-dom";
import { BaseSettingsTable } from "../../../components/BaseSettingsTable";
import { BaseSettingsCell } from "../../../components/BaseSettingsCell";

export type SettingsEmailSchedulesTableProps = {
  emailSchedules: EmailSchedule[];
  tableClassName?: string;
};

export function SettingsEmailSchedulesTable({
  emailSchedules,
  tableClassName = "settings-table settings-table-schedules",
}: SettingsEmailSchedulesTableProps) {
  const navigate = useNavigate();

  const columns: ColumnDef<EmailSchedule, keyof EmailSchedule>[] = [
    {
      accessorKey: "name",
      header: "Name",
      cell: ({ getValue }) => BaseSettingsCell.businessAvatar(getValue() as string),
    },
    {
      accessorKey: "liveboard_name",
      header: "Liveboard",
    },
    {
      accessorKey: "recipients",
      header: "Recipients",
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ getValue }) => BaseSettingsCell.status(getValue() as string),
    },
    {
      accessorKey: "runs",
      header: "Runs",
    },
  ];

  const handleRowClick = (emailSchedule: EmailSchedule) => {
    navigate(`/schedules/${emailSchedule.liveboard_id}`);
  };

  return (
    <BaseSettingsTable
      data={emailSchedules}
      columns={columns}
      tableClassName={tableClassName}
      title="Email Schedules"
      description="This is a list of all the email schedules"
      onRowClick={handleRowClick}
    />
  );
}
