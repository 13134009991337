import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { Stage } from "../components/StagesIndicator";
import { useInitialOnboardingStore } from "./store";
import * as api from "../../../api";
import { useMutation } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { INITIAL_ONBOARDING_TOKEN_COOKIE } from "../../../constants";
import { OnboardingOrchestrator } from "../components/OnboardingOrchestrator";

export function InitialOnboarding() {
  const [stages] = React.useState<Stage[]>([
    {
      path: "/initial-onboarding/welcome",
    },
    {
      path: "/initial-onboarding/choose-users",
    },
    {
      path: "/initial-onboarding/alert-users",
    },
  ]);

  const [searchParams] = useSearchParams();
  const initialOnboardingTokenSearchParam = searchParams.get("token");
  const [cookies, setCookies, removeCookies] = useCookies([INITIAL_ONBOARDING_TOKEN_COOKIE]);
  React.useEffect(() => {
    if (!initialOnboardingTokenSearchParam) {
      return;
    }
    setCookies(INITIAL_ONBOARDING_TOKEN_COOKIE, initialOnboardingTokenSearchParam, {
      secure: true,
    });
  }, [initialOnboardingTokenSearchParam, setCookies]);

  const initialOnboardingToken = initialOnboardingTokenSearchParam
    ? initialOnboardingTokenSearchParam
    : cookies.initial_onboarding_token;

  const initialOnboarding = useInitialOnboardingStore();

  const doFinishStep = useMutation({
    mutationFn: () =>
      api.initiallyOnboardFoodicsAccounts(
        initialOnboardingToken,
        Object.entries(initialOnboarding.selectedAccountIds)
          .filter(([, isSelected]) => isSelected)
          .map(([id]) => id),
      ),
  });

  function cleanup() {
    removeCookies(INITIAL_ONBOARDING_TOKEN_COOKIE);
    initialOnboarding.reset();
  }

  if (!initialOnboardingToken) {
    return <Navigate to="/" />;
  }

  return (
    <OnboardingOrchestrator
      stages={stages}
      finishMutation={doFinishStep}
      cleanup={cleanup}
      onDone={{ navigateTo: "/" }}
      onCancel={{ navigateTo: "/" }}
      onError={{ navigateTo: "/" }}
    />
  );
}
