export class ResponseError extends Error {
  response: Response;

  constructor(message: string, response: Response) {
    super(message);
    this.response = response;
  }
}

export type UserIdentity = {
  email: string;
  business_id: string;
};

export type OrderedLiveboardsCategory = {
  name: string;
  liveboards: { id: string }[];
};

export type User = {
  id: string;
  name: string;
  identities: UserIdentity[];
  avatar: string;
  should_change_password: boolean;
  is_active: boolean;
  is_demo?: boolean;
  meta: {
    foodics: {
      roles: string[];
    };
    norma: {
      enterprise_web_mode: "lite" | "pro";
      liveboard_categories_ordered: OrderedLiveboardsCategory[] | null;
      business_context: string;
    };
  };
  is_root: boolean;
  organization_id: string;
  businesses_count: number;
  venues_count: number;
  organization: Organization;
};

export function mergeUserEmails(user: User): string {
  return Array.from(new Set(user.identities.map((i) => i.email)).values()).join(", ");
}

export enum AppMode {
  PRO = "pro",
  LITE = "lite",
}

export type LoginDetails = {
  business_ref: number;
  email: string;
  password: string;
};

export type LoginResponse = {
  token: { token: string };
};

export type FoodicsRole = {
  id: string;
  name: string;
};

export type FoodicsBranch = {
  id: string;
  name: string;
};

export type FoodicsAccount = {
  id: string;
  name: string;
  email: string;
  branches: FoodicsBranch[];
  roles: FoodicsRole[];
  is_owner: boolean;
};

export type Role = {
  id: string;
  name: string;
};

export type FoodicsMeta = {
  roles: Role[];
};

export type Meta = {
  foodics: FoodicsMeta;
};

export type UserAccount = {
  avatar: string;
  email: string;
  id: string;
  is_active: boolean;
  meta: Meta;
  name: string;
  business_ids: string[];
  should_change_password: boolean;
};

export type LiveboardCategory = {
  name: string;
  icon: string;
  index: number;
};

export type Liveboard = {
  liveboard_id: string;
  liveboard_name: string;
  icon: string;
  is_favorite: boolean;
  created_at: number;
  index: number;
  category?: LiveboardCategory;
};

export type StatusResponse = {
  available: boolean;
};

export type Business = {
  id: string;
  name: string;
  display_name: string;
  venues_count: number;
  users_count: number;
};

export type EmailSchedule = {
  id: string;
  name: string;
  liveboard_id: string;
  liveboard_name: string;
  recipients: number;
  status: string;
  runs: number;
};

export const AllBusiness: Business = {
  id: "ALL",
  // TODO(yannis): name and display_name will need to be translated when an intl lib is introduced in this project
  name: "ALL BUSINESSES",
  display_name: "ALL BUSINESSES",
  venues_count: 0,
  users_count: 0,
};
export function isAllBusiness(businessId: string): boolean {
  return businessId === "ALL";
}

export type Organization = {
  id: string;
  name: string;
};
